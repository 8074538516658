export default [
  {
    header: 'Primeira Caixa',
    action: 'read',
    resource: 'ACL_STOCK',
  },
  {
    title: 'Liberar itens',
    route: 'stock',
    icon: 'ArchiveIcon',
    action: 'read',
    resource: 'ACL_STOCK',
  },
]
