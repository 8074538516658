<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      sm="6"
      md="8"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div v-if="!$route.meta.breadcrumb">
            <h2 class="float-left pr-1 mb-0 breadcrumbs">
              <template v-if="$route.meta.return">
                <router-link :to="{ path: $route.meta.return.url }">
                  <img
                    src="@/assets/images/icons/chevron-right.png"
                    width="20"
                  >
                </router-link>
              </template>
              {{ $route.meta.pageTitle }}
            </h2>
          </div>

          <div v-if="$route.meta.help">
            <b-button
              size="sm"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              @click="clickHelp()"
            >
              <span class="align-middle">?</span>
            </b-button>
          </div>

          <div
            v-if="$route.meta.breadcrumb"
            class="breadcrumb-wrapper"
          >
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      v-if="$route.meta.partners"
      class="content-header-right mb-1"
      md="4"
      sm="6"
      cols="12"
    >
      <div class="partners d-flex align-items-center justify-content-md-end">
        <p class="m-0 pr-1 text-gray-800 font-weight-bolder">
          Apoio:
        </p>
        <div
          v-for="(item, index) in $route.meta.partners"
          :key="index"
        >
          <img
            :src="item.image"
            class="img-fluid logo"
            :alt="item.title"
          >
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },

  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,

  },

  data() {
    return {
      statusModel: false,
      show: true,
    }
  },

  methods: {
    clickHelp() {
      this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', true)
    },
  },

}
</script>

<style lang="scss" scoped>
.partners  {
  .logo {
    max-height: 32px;
  }
  div {
    padding: 0 1rem;

    & + div {
      padding-right: 0;
    }
  }
}
</style>
