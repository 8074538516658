<template>
  <div>
    <layout-vertical>

      <router-view />

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    </layout-vertical>
    <AcceptNotificationModal />
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import AcceptNotificationModal from '../components/accept-notifications/AcceptNotificationModal.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    AcceptNotificationModal,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    this.$store
      .dispatch('notificationConfig/list')
      .then(response => {
        const { data } = response.data
        const notificationConfigSaved = data.worker_notifications_info.filter(notificationConfig => notificationConfig.notification_type === 'CARES' || notificationConfig.notification_type === 'COMMUNICATIONS')
        if (notificationConfigSaved.length === 0) {
          this.$bvModal.show('accept-notification')
        }
        if (notificationConfigSaved.length > 0) {
          this.$store.commit(
            'notificationConfig/UPDATE_COMMUNICATIONS',
            notificationConfigSaved.find(notificationConfig => notificationConfig.notification_type === 'COMMUNICATIONS').is_active,
          )
          this.$store.commit(
            'notificationConfig/UPDATE_CARES',
            notificationConfigSaved.find(notificationConfig => notificationConfig.notification_type === 'CARES').is_active,
          )
          this.$store.commit('notificationConfig/UPDATE_IS_DEFAULT', false)
        }
        data.worker_notifications_info.forEach(workerNotification => {
          if (workerNotification.notification_type === 'BARIATRIC_CARD' && workerNotification.is_active) {
            this.$store.commit('notificationConfig/UPDATE_CARD_IS_NEW_ITEM_TAB', true)
          }
          if (workerNotification.notification_type === 'INDICATORS' && workerNotification.is_active) {
            this.$store.commit('notificationConfig/UPDATE_INDICATORS_IS_NEW_ITEM_TAB', true)
          }
        })
      })
  },
}
</script>
