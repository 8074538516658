export default [
  {
    notification_type: 'REGISTER_PROTOCOL',
    title: 'Você tem pacientes que aguardam o cadastro de protocolo para iniciar sua jornada',
    icon: require('@/assets/images/icons/clipboard-list.svg'),
    route: { name: 'bariatric', query: { filter: 'REGISTER_PROTOCOL' } },
  },
  {
    notification_type: 'HEALTH_SPECIALIST',
    title: 'Você tem pacientes que não concluíram a etapa de especialistas há mais de 35 dias',
    icon: require('@/assets/images/icons/doctor.svg'),
    route: { name: 'bariatric', query: { filter: 'HEALTH_SPECIALIST' } },
  },
  {
    notification_type: 'EXAM',
    title: 'Você tem pacientes que não concluíram a etapa de exames há mais de 35 dias',
    icon: require('@/assets/images/icons/vial.svg'),
    route: { name: 'bariatric', query: { filter: 'EXAM' } },
  },
  {
    notification_type: 'SURGERY_REQUEST',
    title: 'Você tem pacientes que concluíram a etapa de especialistas e ou exames mas não inseriram a data de pedido da autorização da cirurgia.',
    icon: require('@/assets/images/icons/doctor-checked.svg'),
    route: { name: 'bariatric', query: { filter: 'SURGERY_REQUEST' } },
  },
  {
    notification_type: 'SURGERY_REQUEST_APPROVED',
    title: 'A data de pedido da autorização da cirurgia de alguns pacientes ultrapassou os 21 dias úteis.',
    icon: require('@/assets/images/icons/calendar.svg'),
    route: { name: 'bariatric', query: { filter: 'SURGERY_REQUEST_APPROVED' } },
  },
  {
    notification_type: 'SURGERY',
    title: 'Você tem pacientes com cirurgia autorizada mas que não inseriram os dados da cirurgia.',
    icon: require('@/assets/images/icons/hospital.svg'),
    route: { name: 'bariatric', query: { filter: 'SURGERY' } },
  },
  {
    notification_type: 'SURGERY_DONE',
    title: 'A data da cirurgia de alguns pacientes foi ultrapassada e não foi marcada como realizada.',
    icon: require('@/assets/images/icons/checked.svg'),
    route: { name: 'bariatric', query: { filter: 'SURGERY_DONE' } },
  },
]
