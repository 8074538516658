<template>
  <div>
    <b-modal
      id="accept-notification"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <b-container>
        <b-row>
          <b-col class="text-center">
            <feather-icon
              icon="MailIcon"
              size="90"
              stroke="#3279EB"
              stroke-width="1.5"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <h4><strong>Notificações por email</strong></h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <p>Agora você pode receber notificações por e-mail sobre atualizações dos dados na plataforma CUIDO. Gostaria de ser notificado?</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="outline"
              class="mr-1 text-primary"
              @click="save(false)"
            >
              Não
            </b-button>
            <b-button
              variant="primary"
              @click="save(true)"
            >
              Sim
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  methods: {
    save(option) {
      const notificationConfig = [{ notification_type: 'COMMUNICATIONS', is_active: option }, { notification_type: 'CARES', is_active: option }]
      this.$store
        .dispatch('notificationConfig/save', notificationConfig)
        .then(() => {
          if (option) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificações por e-mail aceitas com sucesso!',
                text: 'Você poderá gerenciar suas notificações novamente ao acessar o seu perfil.',
                size: 'sm',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          }
          this.$store.commit('notificationConfig/UPDATE_COMMUNICATIONS', option)
          this.$store.commit('notificationConfig/UPDATE_CARES', option)
          this.$store.commit('notificationConfig/UPDATE_IS_DEFAULT', false)
        })

      this.$bvModal.hide('accept-notification')
    },
  },
}
</script>
<style lang="">

</style>
