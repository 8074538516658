<template>
  <div
    v-if="status.error"
    class="text-center mt-2 mb-2"
  >
    <b-alert
      :variant="status.color"
      show
    >
      <div class="alert-body">
        <span>{{ status.message }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default ({
  components: { BAlert },
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
})
</script>
