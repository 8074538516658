<template>
  <div>
    <b-modal
      id="modal-profile"
      size="lg"
      no-close-on-backdrop
      centered
      header-bg-variant="primary"
      title="Perfil"
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-header="{ close }">
        <h4 class="text-light text-center modal-title">
          Perfil
        </h4>
        <b-button
          variant="outline"
          size="sm"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            stroke="white"
          />
        </b-button>
      </template>
      <b-container
        class="pl-3 pr-3 pt-2"
        fluid
      >
        <b-tabs align="center">
          <b-tab class="mt-4">
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="20"
                stroke="#3279EB"
              />
              Dados do Perfil
            </template>
            <profile-edit />
          </b-tab>
          <b-tab class="mt-4">
            <template #title>
              <feather-icon
                icon="BellIcon"
                size="20"
                stroke="#3279EB"
              />
              Notificações
            </template>
            <notificationConfig />
          </b-tab>
        </b-tabs>

      </b-container>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BTabs, BTab, BContainer, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import profileEdit from '@/views/pages/profile/Profile-edit.vue'
import notificationConfig from '@/views/pages/profile/Notification-config.vue'

export default {
  components: {
    BModal,
    BContainer,
    BTabs,
    BTab,
    BButton,
    profileEdit,
    notificationConfig,
  },
  directives: {
    Ripple,
  },
  methods: {
    closeModal() {
      this.$store.commit('profile/UPDATE_SHOW_PROFILE', false)
    },
  },
}
</script>

<style lang="scss">

.modal-title {
  margin-top: 4px
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
