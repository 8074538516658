<template>
  <div>
    <b-row align-h="end">
      <b-col
        class="text-right"
        cols="5"
      >
        <feather-icon
          icon="MailIcon"
          size="20"
          stroke="black"
        />
        <span class="ml-1">e-mail</span>
      </b-col>
    </b-row>
    <hr class="mt-2 mb-3">
    <b-row align-h="between">
      <b-col cols="7">
        <h5>Comunicações em geral</h5>
        <span>Para ficar por dentro das novidades ou de comunicados da plataforma.</span>
      </b-col>
      <b-col
        class="text-right"
        cols="4"
      >
        <b-form-checkbox
          v-model="communications"
          :checked="communications"
          switch
          inline
        />

      </b-col>
    </b-row>
    <hr class="mt-2 mb-3">
    <b-row align-h="between">
      <b-col cols="7">
        <h5>Linhas de cuidado</h5>
        <span>Para saber sobre o status dos pacientes na linha de cuidado liberada.</span>
      </b-col>
      <b-col
        class="text-right"
        cols="4"
      >
        <b-form-checkbox
          v-model="cares"
          :checked="cares"
          switch
          inline
        />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col
        class="text-center mt-4"
      >
        <b-button
          variant="primary"
          type="submit"
          block
          class="mt-1"
          :disabled="disabledToSave"
          @click="save()"
        >
          <b-spinner
            v-show="loading"
            small
          />
          SALVAR
        </b-button>

        <div class="mt-2 mb-2 text-center">
          <b-button
            block
            type="button"
            variant="outline-primary"
            @click="$bvModal.hide('modal-profile')"
          >
            VOLTAR
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BSpinner,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  setup() {
    const disabledToSave = ref(true)
    const loading = ref(false)

    return {
      disabledToSave,
      loading,
    }
  },
  data() {
    return {
      communications: this.$store.state.notificationConfig.communications,
      cares: this.$store.state.notificationConfig.cares,
    }
  },
  watch: {
    communications() {
      this.disabledToSave = false
    },
    cares() {
      this.disabledToSave = false
    },
  },
  methods: {
    save() {
      this.loading = true
      const notificationConfig = [
        { notification_type: 'COMMUNICATIONS', is_active: this.communications },
        { notification_type: 'CARES', is_active: this.cares },
      ]

      this.$store
        .dispatch('notificationConfig/save', notificationConfig)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gerenciamento de notificações salvo com sucesso!',
              size: 'sm',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          this.$store.commit('notificationConfig/UPDATE_COMMUNICATIONS', this.communications)
          this.$store.commit('notificationConfig/UPDATE_CARES', this.cares)
          this.$store.commit('notificationConfig/UPDATE_IS_DEFAULT', false)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              size: 'sm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
          this.$bvModal.hide('modal-profile')
        })
    },
  },
}
</script>
<style>
</style>
