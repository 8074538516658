export default [
  {
    title: 'Resumo',
    route: 'resume',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Pacientes',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Bariátrica',
        route: 'bariatric',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Todos os pacientes',
        route: 'patient',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Favoritos',
    route: 'favorite',
    icon: 'StarIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Biblioteca',
    route: 'library',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'ACL',
  },
]
