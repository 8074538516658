<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-img
      class="position"
      src="@/assets/images/logo/CuidoLogo.png"
    />

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <!-- <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p> -->
            <!-- <span class="user-status">{{ group }}</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :text="initials"
            class="badge-minimal"
          />
          <feather-icon
            class="ml-1"
            size="25"
            icon="ChevronDownIcon"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="profile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <profile-tab />
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BImg,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import profileTab from '@/views/pages/profile/Profile-tab.vue'
import NotificationDropdown from '@core/components/notification-dropdown/NotificationDropdown.vue'
import store from '@/store'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    profileTab,
    NotificationDropdown,
    // Navbar Components
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      name: '',
      group: '',
      initials: '',
    }
  },

  computed: {
    showProfile() {
      return this.$store.state.profile.showProfile
    },
  },

  watch: {
    showProfile(value) {
      if (value === true) {
        this.$bvModal.show('modal-profile')
      }
    },
  },

  mounted() {
    this.user()
    this.$store.commit('profile/UPDATE_SHOW_PROFILE', false)
  },

  methods: {
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })

      store.commit('bariatricListFilter/RESET_STATE')
    },
    user() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const nameArray = userData.name.split(' ')
      const len = nameArray.length
      this.name = `${nameArray['0']} ${nameArray[len - 1]}`
      this.initials = (nameArray['0'].substr(0, 1) + nameArray[len - 1].substr(0, 1)).toUpperCase()
      this.group = userData.type.toUpperCase()
    },
    profile() {
      this.$store.commit('profile/UPDATE_SHOW_PROFILE', true)
    },
  },
}
</script>

<style scoped>
.position {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 125px;
}

</style>
