var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"profileEdit",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[[_c('div',{staticClass:"text-center"},[_c('b-avatar',{attrs:{"size":"60px","variant":"light-primary","text":_vm.avatarText(_vm.name)}})],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"fullName","label":"Nome completo"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullName","name":"fullName","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gênero","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Genero"}},[_c('v-select',{ref:"select",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.genderOption},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Nenhum registro encontrado. ")])])],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"email","label":"E-mail"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de conselho profissional","label-for":"board"}},[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"select",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOption,"label":"name"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Nenhum registro encontrado. ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"UF","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"UF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"select",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.stateOption,"label":"code","reduce":function (state) { return state.code; }},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Nenhum registro encontrado. ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"board","label":"Conselho regional"}},[_c('validation-provider',{attrs:{"name":"Conselho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.company)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"institutionName","label":"Instituição de saúde","rules":"required"}},[_c('validation-provider',{attrs:{"name":"Instituição"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',[_c('alert-message',{attrs:{"status":_vm.status}})],1)],1)],_c('b-col',{staticClass:"text-center"},[_c('div',{staticClass:"mt-2 text-center"},[_c('b-link',{attrs:{"to":{name:'password-email'}}},[_c('u',[_vm._v("SOLICITAR ALTERAÇÃO DE SENHA")])])],1)]),_c('div',{staticClass:"mt-3 ml-2"},[_vm._v(" Consulte o "),_c('b-link',{attrs:{"href":"https://cucohealth.com/termo-de-uso-plataforma","target":"_blank"}},[_c('u',[_vm._v("Termo de Uso")])]),_vm._v(" e a "),_c('b-link',{attrs:{"href":"https://cucohealth.com/politica-privacidade","target":"_blank"}},[_c('u',[_vm._v("Política de Privacidade")])])],1),_c('b-col',{staticClass:"text-center mt-3"},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","type":"submit","block":"","disabled":_vm.loading || invalid},on:{"click":function($event){return _vm.save()}}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"small":""}}),_vm._v(" SALVAR ")],1),_c('div',{staticClass:"mt-2 mb-2 text-center"},[_c('b-button',{attrs:{"block":"","type":"button","variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-profile')}}},[_vm._v(" VOLTAR ")])],1)],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }