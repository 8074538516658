<template>
  <div>
    <validation-observer
      ref="profileEdit"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
      >
        <template>
          <div class="text-center">
            <b-avatar
              size="60px"
              variant="light-primary"
              :text="avatarText(name)"
            />
          </div>
          <b-row class="mt-2">
            <b-col md="6">

              <b-form-group
                label-for="fullName"
                label="Nome completo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required"
                >
                  <b-form-input
                    id="fullName"
                    v-model="name"
                    name="fullName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>

            <b-col md="6">
              <b-form-group
                label="Gênero"
                label-for="gender"
              >
                <validation-provider
                  name="Genero"
                >
                  <v-select
                    ref="select"
                    v-model="gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOption"
                  >
                    <div slot="no-options">
                      Nenhum registro encontrado.
                    </div>
                  </v-select>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="email"
                label="E-mail"
              >
                <b-form-input
                  v-model="email"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Tipo de conselho profissional"
                label-for="board"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo"
                  rules="required"
                >
                  <v-select
                    ref="select"
                    v-model="type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOption"
                    label="name"
                  >
                    <div slot="no-options">
                      Nenhum registro encontrado.
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="UF"
                label-for="state"
              >
                <validation-provider
                  #default="{ errors }"
                  name="UF"
                  rules="required"
                >
                  <v-select
                    ref="select"
                    v-model="state"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateOption"
                    label="code"
                    :reduce="state => state.code"
                  >
                    <div slot="no-options">
                      Nenhum registro encontrado.
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label-for="board"
                label="Conselho regional"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Conselho"
                  rules="required"
                >
                  <b-form-input v-model="code" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="company"
              md="12"
            >
              <b-form-group
                label-for="institutionName"
                label="Instituição de saúde"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instituição"
                >
                  <b-form-input
                    v-model="company"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <alert-message :status="status" />
            </b-col>
          </b-row>
        </template>
        <b-col
          class="text-center"
        >

          <div class="mt-2 text-center">
            <b-link
              :to="{name:'password-email'}"
            >
              <u>SOLICITAR ALTERAÇÃO DE SENHA</u>
            </b-link>
          </div>

        </b-col>
        <div class="mt-3 ml-2">
          Consulte o
          <b-link
            href="https://cucohealth.com/termo-de-uso-plataforma"
            target="_blank"
          >
            <u>Termo de Uso</u>
          </b-link> e a
          <b-link
            href="https://cucohealth.com/politica-privacidade"
            target="_blank"
          >
            <u>Política de Privacidade</u>
          </b-link>
        </div>
        <b-col
          class="text-center mt-3"
        >

          <b-button
            variant="primary"
            type="submit"
            block
            class="mt-1"
            :disabled="loading || invalid"
            @click="save()"
          >
            <b-spinner
              v-show="loading"
              small
            />
            SALVAR
          </b-button>

          <div class="mt-2 mb-2 text-center">
            <b-button
              block
              type="button"
              variant="outline-primary"
              @click="$bvModal.hide('modal-profile')"
            >
              VOLTAR
            </b-button>
          </div>
        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BAvatar, BCol, BForm, BFormInput, BFormGroup, BRow, BButton, BLink, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import avatarText from '@/utils/filter'
import AlertMessage from '@/layouts/components/AlertMessage.vue'

export default {
  components: {
    BAvatar,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BLink,
    BSpinner,
    AlertMessage,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      name: '',
      email: '',
      type: '',
      typeOption: [],
      board: '',
      boardOption: [],
      state: '',
      stateOption: [],
      gender: '',
      genderOption: [],
      workerGender: '',
      company: '',
      code: '',
      loading: false,
      status: {},
      required,
      avatarText,
    }
  },
  mounted() {
    localize(this.$store.getters['cuido/locale'])

    this.listType()
    this.listState()
    this.listGender()
    this.listWorker()
  },
  beforeDestroy() {
    this.status = {}
    this.listWorker()
  },
  methods: {
    listType() {
      this.$store.dispatch('profile/listType')
        .then(response => {
          this.typeOption = (response.data.data).sort((a, b) => a.localeCompare(b))
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    listState() {
      this.$store.dispatch('profile/listState')
        .then(response => {
          this.stateOption = (response.data.data.states_info).sort((a, b) => a.code.localeCompare(b.code))
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    listGender() {
      this.$store.dispatch('profile/listGender')
        .then(response => {
          this.genderOption = (response.data.data.genders_info).sort((a, b) => a.localeCompare(b))
          this.genderOption.splice(0, 3, 'Feminino', 'Masculino', 'Outros')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    listWorker() {
      this.$store.dispatch('profile/listWorker')
        .then(response => {
          const worker = response.data.data.worker_info
          this.name = worker.name
          this.workerGender = worker.gender
          switch (worker.gender) {
            case 'M':
              this.gender = 'Masculino'
              break
            case 'F':
              this.gender = 'Feminino'
              break
            case 'OTHERS':
              this.gender = 'Outros'
              break
            default:
              this.gender = ''
          }
          this.email = worker.email

          if (worker.registrations_info.length > 0) {
            this.registrationId = worker.registrations_info[0].id
            this.type = worker.registrations_info[0].type
            this.state = worker.registrations_info[0].state
            this.code = worker.registrations_info[0].code
          } else {
            this.type = ''
            this.state = ''
            this.code = ''
          }

          if (worker.companies_info.length > 0) {
            this.company = worker.companies_info[0].name
          } else {
            this.company = ''
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    save() {
      this.$refs.profileEdit.validate().then(success => {
        if (success) {
          this.loading = true

          switch (this.gender) {
            case 'Masculino':
              this.workerGender = 'M'
              break
            case 'Feminino':
              this.workerGender = 'F'
              break
            case 'Outros':
              this.workerGender = 'OTHERS'
              break
            default:
              this.workerGender = ''
          }

          const dataForm = {
            worker_info: {
              name: this.name,
              gender: this.workerGender,
            },
            registrations_info: [
              {
                id: this.registrationId,
                type: this.type,
                state: this.state,
                code: this.code,
              },
            ],
          }

          this.$store.dispatch('profile/save', dataForm)
            .then(() => {
              this.$swal({
                title: 'Sucesso',
                text: 'Perfil salvo com sucesso!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.isConfirmed) {
                    this.$router.push('/').catch(() => {})
                  }
                })
            })
            .catch(error => {
              const object = Object.is(error)
              if (object === true) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'Erro interno. Tente novamente.',
                }
              } else if (error.data.error.code === 200009) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'Dados de conselho inválidos ou já cadastrados.',
                }
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  h5 {
     color: white;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
