<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <span class="d-none d-xl-inline ml-1"><strong>Notificações</strong></span>
    </template>

    <li
      class="dropdown-menu-header"
    >
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificações
        </h4>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div
        v-if="notifications.length === 0"
        class="mt-1"
      >
        <p class="text-center">
          Você não tem novas notificações.
        </p>
      </div>
      <b-dropdown-item
        v-for="notification in notifications"
        :key="notification.id"
        :class="(notification.read_at)?'bg-light':''"
        :to="notificationInfo(notification.notification_type).route"
      >
        <b-media
          class="bg-transparent text-body"
          @click="readNotification(notification)"
        >
          <template #aside>
            <b-img
              class="position"
              :src="notificationInfo(notification.notification_type).icon"
            />
          </template>
          <h6 :class="(notification.read_at)?'text-wrap text-black-50':'text-wrap'">
            {{ notificationInfo(notification.notification_type).title }}
          </h6>
          <small
            :class="(notification.read_at)?'text-black-50 font-weight-bold':'text-primary font-weight-bold'"
          >
            {{ formatDate(notification.created_at) }}
          </small>
        </b-media>
      </b-dropdown-item>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import { formatDate } from '@/@core/utils/filter'
import {
  BNavItemDropdown, BMedia, BDropdownItem, BImg,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import notificationRepository from './notifications'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BDropdownItem,
    VuePerfectScrollbar,
    BImg,
  },
  data() {
    return {
      notificationRepository,
      notificationsUnreaded: [],
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    unreadNotifications() {
      return this.notifications.filter(notification => !notification.read_at)
    },
  },
  created() {
    this.listNotification()

    setInterval(this.listNotification, 30 * 60 * 1000)
  },
  methods: {
    listNotification() {
      this.$store.dispatch('notification/list')
        .then(response => {
          const { data } = response.data
          this.updateNotifications(data.worker_notifications_info)
        })
    },
    updateNotifications(notifications) {
      this.notifications.splice(0, this.notifications.length)
      this.notifications.push(...notifications)
    },
    readNotification(notification) {
      if (!notification.read_at) {
        this.$store.dispatch('notification/read', notification.id)
          .then(() => {
            this.listNotification()
          })
      }
    },
    formatDate(value) {
      return formatDate(value)
    },
    notificationInfo(notificationsType) {
      return notificationRepository.find(item => item.notification_type === notificationsType)
    },
  },
}
</script>

<style>

</style>
